import React, { useState, useEffect } from 'react'
import { Avatar } from 'baseui/avatar'
import { Display4 } from 'baseui/typography'
import { useStyletron } from 'baseui'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal'
import { Checkbox } from 'baseui/checkbox'
import { Button, KIND, SHAPE, SIZE } from 'baseui/button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Notification } from 'baseui/notification'
import Emoji from './Emoji'
// import { FiExternalLink } from 'react-icons/fi'
import whatsAppLogo from '../images/WhatsApp.svg'
// import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
// import { FiInstagram } from 'react-icons/fi'

const SuccessModal = ({ waLink, isOpen, setIsOpen, qrDataUrl }) => {
  const [css] = useStyletron()
  const [copied, setCopied] = useState(false)
  // const [dmCopied, setDMCopied] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [downloadString, setDownloadString] = useState(qrDataUrl)
  const [addTextToQR, setAddTextToQR] = useState(false)

  const goToPremium = () => {
    window.plausible('Modal To App')
    window.open('https://app.wa.link/register', '_blank')
  }

  const checkCopiedAndClose = () => {
    if (copied) {
      setCopied(false)
      setIsOpen(false)
      setShowNotification(false)
    } else {
      setShowNotification(true)
    }
  }

  useEffect(() => {
    if (addTextToQR) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = 300
      canvas.height = 320

      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, 300, 320)
      const image = new Image()
      image.src = qrDataUrl
      image.onload = function() {
        ctx.drawImage(image, 0, 0)
        ctx.font = 'bold 20px Helvetica'
        ctx.fillStyle = 'black'
        ctx.fillText(waLink.replace('https://', ''), 160, 305)
        setDownloadString(canvas.toDataURL('image/png'))
      }
    } else {
      setDownloadString(qrDataUrl)
    }
  }, [addTextToQR, waLink, qrDataUrl])

  return (
    <Modal
      isOpen={isOpen}
      onClose={checkCopiedAndClose}
      unstable_ModalBackdropScroll={true}
      overrides={{
        Dialog: {
          style: {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
          },
        },
      }}
    >
      <ModalHeader>This is your WhatsApp short link</ModalHeader>
      <ModalBody>
        <p>
          Copy and share it anywhere you want to be contacted instantly.
          {/* (Use{' '}
          <StatefulPopover
            showArrow
            content={
              <>
                <Paragraph2 padding="scale300">
                  Currently <FiInstagram style={{ verticalAlign: 'middle' }} />{' '}
                  Instagram presents an error when sending messages with links
                  that redirect to any Meta Apps. Use this version of your link
                  if you are going to send it in Instagram DM messages.
                  <br />
                  <CopyToClipboard
                    text={'https://' + waLink.replace('https://', 'dm.')}
                    onCopy={() => setDMCopied(true)}
                  >
                    <a href="/" onClick={e => e.preventDefault()}>
                      {dmCopied ? 'Copied!' : 'Copy link for Instagram DM'}
                    </a>
                  </CopyToClipboard>
                </Paragraph2>
              </>
            }
            accessibilityType={'tooltip'}
            triggerType={TRIGGER_TYPE.hover}
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: {
                  width: '300px',
                  marginTop: '10px',
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
              Inner: {
                style: {
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
            }}
          >
            <strong style={{ textDecoration: 'underline' }}>
              {waLink.replace('https://', 'dm.')}
            </strong>
          </StatefulPopover>{' '}
          to send the link in Instagram messages) */}
        </p>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          })}
        >
          <Avatar name="WA logo" size="scale1200" src={whatsAppLogo} />
          <a
            href={waLink}
            target="_blank"
            rel="noopener noreferrer"
            className={css({
              marginLeft: '5px',
              wordWrap: 'break-word',
              textDecoration: 'none',
            })}
          >
            <Display4>{waLink.replace('https://', '')}</Display4>
          </a>
        </div>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '10px',
            marginBottom: '10px',
            cursor: 'pointer',
          })}
          onClick={goToPremium}
        >
          <div
            className={css({
              color: 'rgb(54, 135, 89)',
              // color: '#fff',
              backgroundColor: ' rgb(240, 249, 244)',
              // backgroundColor: '#1fd1f9',
              // backgroundImage:
              //   'linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%)',
              // 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
              width: '100%',
              borderRadius: '10px',
              padding: '1rem',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
              // boxShadow: 'rgba(0,0,0, 0.8) 0px 1px 5px',
              border: '1px solid rgb(54, 135, 89)',
              textAlign: 'center',
              transition: '0.2s',
              ':hover': {
                // boxShadow: 'rgba(0,0,0, 0.8) 0px 1px 5px',
                boxShadow: 'rgba(0,0,0, 0.4) 0px 2px 5px',
              },
            })}
          >
            <p style={{ fontSize: '15px' }}>
              Get a <Emoji symbol={'👑'} />{' '}
              <a
                href="https://app.wa.link/register"
                target="_blank"
                rel="noopener noreferrer"
                alt="Plan premium"
                onClick={e => e.preventDefault()}
                // style={{ color: '#fff' }}
              >
                Premium Plan
              </a>{' '}
              from <strong>USD $6/year</strong> to create wa.link(s) with:
              {/* Get your own <strong> wa.link/YourBrand </strong> with a{' '}
              <Emoji symbol={'👑'} />
              <OutboundLink
                href="https://create.wa.link/premium"
                target="_blank"
                rel="noopener noreferrer"
                alt="Premium plan"
                onClick={e => e.preventDefault()}
                style={{ color: '#fff' }}
              >
                Premium plan
              </OutboundLink>
              <Emoji symbol={'👑'} />, so you can track clicks and edit the link
              info at any time. */}
            </p>
            <ul
              className="checkList"
              // style={{ textAlign: 'left', listStyleType: 'none' }}
            >
              <li>
                Branded link <strong>(wa.link/YourBusiness)</strong>
              </li>
              <li>
                <strong>Edit </strong> phone, message and URL, anytime.
              </li>
              <li>
                <strong>Analytics</strong> of clicks per hour, day or month with
                referral, operating system and country.
              </li>
              <li>
                Access to Multilink page, <strong>Multiagent service</strong>,
                links search engine and more...
              </li>
            </ul>
            <Button
              shape={SHAPE.pill}
              size={SIZE.compact}
              // endEnhancer={() => <FiExternalLink size={16} />}
              overrides={{
                BaseButton: {
                  style: {
                    marginTop: '1rem',
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                  },
                },
              }}
            >
              <Emoji symbol={'👑'} /> Get Premium
            </Button>
          </div>
        </div>
        {!copied && showNotification && (
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-evenly',
              marginTop: '10px',
            })}
          >
            <Notification
              overrides={{
                Body: {
                  style: {
                    borderTopRightRadius: '10px',
                    borderTopLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                  },
                },
              }}
            >
              Be sure to copy the link before close
            </Notification>
          </div>
        )}
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-evenly',
          })}
        >
          <img
            src={downloadString}
            alt="generated-qr"
            style={{ width: 300, height: addTextToQR ? 320 : 300 }}
          />
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          })}
        >
          <Checkbox
            checked={addTextToQR}
            onChange={e => setAddTextToQR(e.target.checked)}
          >
            Add link to QR code
          </Checkbox>
        </div>
      </ModalBody>
      <ModalFooter>
        <CopyToClipboard text={waLink} onCopy={() => setCopied(true)}>
          <ModalButton
            shape={SHAPE.pill}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: '#00b66c',
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            {copied ? 'Copied!' : 'Copy link'}
          </ModalButton>
        </CopyToClipboard>
        <a
          download={waLink.replace('https://', '') + '.png'}
          href={downloadString}
          style={{ textDecoration: 'none' }}
        >
          <ModalButton
            kind={KIND.secondary}
            shape={SHAPE.pill}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            Download QR
          </ModalButton>
        </a>
      </ModalFooter>
    </Modal>
  )
}

export default SuccessModal
